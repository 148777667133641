<template>
  <div class="formdata" style="padding: 20px">
    <el-form ref="form" :model="form" :rules="rules" label-width="140px">
      <el-row>
        <el-col :span="10">
          <el-form-item required="true" label="药品编码:">
            <span>{{ form.medicalCode }}</span>
          </el-form-item>
        </el-col>
        <el-col :span="10">
          <el-form-item label="药品名称:" prop="medicalName">
            <el-input
              v-model="form.medicalName"
              v-check-permission
              placeholder="请输入药品名称"
              maxlength="100"
              :disabled="isDetail"
              style="width: 60%"
            />
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="10">
          <el-form-item label="药品通用编码:">
            <el-input
              v-model="form.commonCode"
              v-check-permission
              placeholder="请输入药品通用编码"
              style="width: 60%"
              :disabled="isDetail"
            />
          </el-form-item>
        </el-col>
        <el-col :span="10">
          <el-form-item label="药品通用名:">
            <el-input
              v-model="form.commonName"
              v-check-permission
              placeholder="请输入药品通用名"
              maxlength="200"
              style="width: 60%"
              :disabled="isDetail"
            />
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="10">
          <el-form-item label="英文名:">
            <el-input
              v-model="form.englishName"
              v-check-permission
              placeholder="请输入英文名"
              maxlength="100"
              style="width: 60%"
              :disabled="isDetail"
            />
          </el-form-item>
        </el-col>
        <el-col :span="10">
          <el-form-item label="进价:" prop="purchasePrice">
            <el-input
              v-model="form.purchasePrice"
              v-check-permission
              placeholder="请输入进价"
              style="width: 60%"
              :disabled="isDetail"
            />
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="10">
          <el-form-item label="包装数量:" prop="packingNum">
            <el-input
              v-model="form.packingNum"
              v-check-permission
              placeholder="请输入包装数量"
              maxlength="10"
              style="width: 60%"
              :disabled="isDetail"
            />
          </el-form-item>
        </el-col>
        <el-col :span="10">
          <el-form-item label="单价(零售价):" prop="retailPrice">
            <el-input
              v-model="form.retailPrice"
              v-check-permission
              placeholder="请输入单价"
              :disabled="isDetail"
              style="width: 60%"
            />
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="10">
          <el-form-item label="规格包装:" prop="specsPacking">
            <el-input
              v-model="form.specsPacking"
              v-check-permission
              placeholder="请输入规格包装"
              maxlength="200"
              :disabled="isDetail"
              style="width: 60%"
            />
          </el-form-item>
        </el-col>
        <el-col :span="10">
          <el-form-item label="药品类别名称:" prop="categoryName">
            <el-input
              v-model="form.categoryName"
              v-check-permission
              placeholder="请输入药品类别名称"
              maxlength="200"
              :disabled="isDetail"
              style="width: 60%"
            />
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="10">
          <el-form-item label="规格:" prop="specs">
            <el-input
              v-model="form.specs"
              v-check-permission
              placeholder="请输入规格"
              maxlength="200"
              :disabled="isDetail"
              style="width: 60%"
            />
          </el-form-item>
        </el-col>
        <el-col :span="10">
          <el-form-item label="给药途径:">
            <el-input
              v-model="form.routeOfAdministration"
              v-check-permission
              placeholder="请输入给药途径"
              maxlength="200"
              :disabled="isDetail"
              style="width: 60%"
            />
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="10">
          <el-form-item label="最小使用单位:" prop="minUseUnit">
            <el-input
              v-model="form.minUseUnit"
              v-check-permission
              placeholder="请输入最小使用单位"
              style="width: 60%"
              :disabled="isDetail"
            />
          </el-form-item>
        </el-col>
        <el-col :span="10">
          <el-form-item label="最小包装单位:" prop="minPackingUnit">
            <el-input
              v-model="form.minPackingUnit"
              v-check-permission
              placeholder="请输入最小包装单位"
              maxlength="10"
              :disabled="isDetail"
              style="width: 60%"
            />
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="10">
          <el-form-item label="生产厂家:" prop="productionName">
            <el-input
              v-model="form.productionName"
              v-check-permission
              placeholder="请输入生产厂家"
              style="width: 60%"
              maxlength="100"
              :disabled="isDetail"
            />
          </el-form-item>
        </el-col>
        <el-col :span="10">
          <el-form-item label="中西药标记:" prop="medicalType">
            <el-select v-model="form.medicalType" :disabled="isDetail">
              <el-option
                v-for="item in symbol"
                :key="item.value"
                :value="item.value"
                :label="item.text"
              />
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="10">
          <el-form-item label="甲乙类标志:" prop="symbol">
            <el-input
              v-model="form.symbol"
              v-check-permission
              placeholder="请输入甲乙类标志"
              style="width: 60%"
              :disabled="isDetail"
            />
          </el-form-item>
        </el-col>
        <el-col :span="10">
          <el-form-item label="状态:" prop="status">
            <el-select v-model="form.status" :disabled="isDetail">
              <el-option
                v-for="item in drugStatusList"
                :key="item.value"
                :value="item.value"
                :label="item.text"
              />
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="10">
          <el-form-item label="主要成分:" prop="mainComponents">
            <el-input
              v-model="form.mainComponents"
              v-check-permission
              placeholder="请输入主要成分"
              style="width: 60%"
              :disabled="isDetail"
            />
          </el-form-item>
        </el-col>
        <el-col :span="10">
          <el-form-item label="批准文号:">
            <el-input
              v-model="form.approvalNumber"
              v-check-permission
              placeholder="请输入批准文号"
              style="width: 60%"
              :disabled="isDetail"
            />
          </el-form-item>
        </el-col>
      </el-row>
      <el-form-item>
        <el-button v-if="!isDetail" type="primary" @click="saveData()">
          保存
        </el-button>
        <el-button type="primary" @click="goBack"> 返回 </el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import {
  percentNum,
  numPoint,
  codeRule,
  onlyAB,
} from '@/utils/verificationRule.js';
import { symbolList, drugStatus, symbol } from '@/utils/component/publicList';
export default {
  name: 'Details',
  data() {
    return {
      isDetail: Number(this.$route.query.type) === 0,
      symbolList: symbolList,
      symbol: symbol,
      drugStatusList: drugStatus,
      form: {
        mainComponents: '',
        approvalNumber: '',
        routeOfAdministration: '',
        specs: '',
        categoryName: '',
        packingNum: '',
        englishName: '',
        medicalCode: '',
        medicalType: '',
        productionName: '',
        status: '',
        purchasePrice: '',
        retailPrice: '',
        specsPacking: '',
        commonCode: '',
        minUseUnit: '',
        medicalName: '',
        commonName: '',
        minPackingUnit: '',
        pocketProportion: '',
        symbol: '',
      },

      rules: {
        minUseUnit: [
          { required: true, message: '请输入最小使用单位', trigger: 'blur' },
        ],
        packingNum: [
          { required: true, message: '请输入包装数量', trigger: 'blur' },
        ],
        productionName: [
          { required: true, message: '请输入生产厂家', trigger: 'blur' },
        ],
        medicalName: [
          { required: true, message: '请输入药品名称', trigger: 'blur' },
        ],
        specs: [{ required: true, message: '请输入药品规格', trigger: 'blur' }],
        minPackingUnit: [
          { required: true, message: '请输入最小包装单位', trigger: 'blur' },
        ],
        specsPacking: [
          { required: true, message: '请输入规格包装', trigger: 'blur' },
        ],
        medicalType: [
          { required: true, message: '请选择中西药标记', trigger: 'change' },
        ],
        status: [{ required: true, message: '请选择状态', trigger: 'change' }],
        purchasePrice: [
          { required: false, validator: numPoint, trigger: 'change' },
        ],
        retailPrice: [
          { required: true, validator: numPoint, trigger: 'change' },
        ],
        pocketProportion: [
          { required: false, validator: percentNum, trigger: 'change' },
        ],
        commonCode: [
          { required: false, validator: codeRule, trigger: 'change' },
        ],
        symbol: [{ required: false, validator: onlyAB, trigger: 'change' }],
      },
    };
  },
  computed: {},
  watch: {},
  mounted() {
    this.getDrugDetail();
  },
  methods: {
    ...mapActions('basicDictionary', ['drugEdit']),
    getDrugDetail() {
      this.form = JSON.parse(this.$route.query.drug);
    },
    // 编辑药品，保存
    saveData() {
      this.$refs['form'].validate((valid) => {
        if (valid) {
          const param = {
            ...this.form,
          };
          this.drugEdit(param).then((res) => {
            if (res.code === 0) {
              this.$message.success('编辑药品成功');
              this.$router.go(-1);
            }
          });
        } else {
          return false;
        }
      });
    },
    goBack() {
      this.$router.go(-1);
    },
  },
};
</script>

<style lang="scss" scoped>
.formdata {
  label {
    font-size: 14px;
    color: #606266;
  }

  .input_el {
    width: 30%;
  }
}
.labelStyle {
  margin-right: 10px;
}

.serviceDetail {
  font-size: 16px;
  color: red;
}

.row-bg {
  padding: 10px 0;
  background-color: #f9fafc;
}

.orderstatus {
  padding: 10px 5px;
  margin-left: 40px;
  border: 1px solid black;
}
</style>
